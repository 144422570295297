import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const DryerGrafico = (props) => {
  const { equipamento } = props;

  const [humidity, setHumidity] = useState(null)
  const [optionshumidity, setOptionshumidity] = useState(null)
  const [temperatura, setTemperatura] = useState(null)
  const [optionstemperatura, setOptionstemperatura] = useState(null)

  var mediahumidity = 0
  var mediapeso = 0
  var mediatemperatura = 0

  useEffect(() => {
    const colorA = 'rgba(143, 173, 21)';
    const colorB = 'rgba(28, 66, 32, 0.8)';

    let labels = equipamento.map(equipamento => equipamento.date);
    const humidityLabel = {
      labels,
      datasets: [{
        labels,
        label: 'UMIDADE',
        data: equipamento.map((equipamento) => (parseFloat(equipamento.umid).toFixed(1))),
        borderColor: colorB,
        //backgroundColor: backgroundGraphics,
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          align: 'end',
          font: {
            weight: 'bold',
            size: 12,
          }
        },
      },/* 
      {
        label: 'Média',
        data: equipamento.map((equipamento) => { return mediahumidity }),
        borderColor: colorB,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: false,
        datalabels: {
          display: false,
        }
      }, */
      ]
    }
    setHumidity(humidityLabel)

    let number = equipamento.map((equipamento) => (parseFloat(equipamento.umid).toFixed(1)))
    let maxValue = Math.max(...number)
    let minValue = Math.min(...number)

    const optionsHumididy = {
      scales: {
        y: {
          stepSize: 4,
          min: maxValue + 2,
          max: minValue - 2,
        },
        x: {},
      }
    };
    setOptionshumidity(optionsHumididy)

    //----------------------------------------------------------------

    // labels = equipamento.map(equipamento => dateFormat(equipamento.date, "dd/mm' | 'HH:MM TT"));

    labels = equipamento.map(equipamento => equipamento.date);
    const temperaturaLabel = {
      labels,
      datasets: [{
        labels,
        label: 'TEMPERATURA',
        data: equipamento.map((equipamento) => (parseFloat(equipamento.temp).toFixed(1))),
        borderColor: colorB,
        //backgroundColor: backgroundGraphics,
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          align: 'end',
          font: {
            weight: 'bold',
            size: 12,
          }
        },
      },/* 
      {
        label: 'Média',
        data: equipamento.map((equipamento) => { return mediatemperatura }),
        borderColor: colorB,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: false,
        datalabels: {
          display: false,
        }
      }, */
      ]
    }
    setTemperatura(temperaturaLabel)

    number = equipamento.map((equipamento) => (parseFloat(equipamento.temp).toFixed(1)))
    maxValue = Math.max(...number)
    minValue = Math.min(...number)

    const optionsPeso = {
      scales: {
        y: {
          stepSize: 4,
          min: maxValue + 2,
          max: minValue - 2,
        },
        x: {},
      }
    };
    setOptionstemperatura(optionsPeso)
  }, [equipamento])

  const plugins = {
    afterDraw: function (chart) {
      if (chart.data.datasets.length > 0) {
        if (chart.data.datasets[0].data.length === 0) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (height / 160).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "top";
          var text = "Nenhuma Informação Disponível",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }
    },
  }

  return (
    <Card>
      <div>
        {
          humidity && optionshumidity &&
          <Line
            options={optionshumidity}
            data={humidity}
            plugins={[plugins]}
            height={60}
          />
        }
        <div style={{ "padding": "0px", "background": "#DCE7E9" }}>
          <div style={{ "heigth": "10px", "background": "#539a73", "padding": "3px" }}>
          </div>
        </div>
        {
          temperatura && optionstemperatura &&
          <Line
            options={optionstemperatura}
            data={temperatura}
            plugins={[plugins]}
            height={60}
          />
        }
      </div>
    </Card>
  )
}
export default DryerGrafico

import React, { useEffect, useState } from "react";
import axios from 'axios';
import DryerGrafico from "./components/DryerGrafico";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Header from '../../components/Header/Header'
import { Col, Container, Row } from 'react-bootstrap'

const url = process.env.REACT_APP_API_MONITORA

const Dryer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [equipamento, setEquipamento] = useState([])
  const [amount, setAmount] = useState(100)
  const [nsEquipamento, setNsEquipamento] = useState("SHTM-00002")
  const [dataInicio, setDataInicio] = useState()
  const [dataFim, setDataFim] = useState()
  const [value, setValue] = useState([])

  var arrEquip, arraySet, resp, data = []

  const handleInformation = () => {
    try {
      axios.all([
        axios.get(`${url}/measurements/dryer/lastn/${nsEquipamento}/${amount}`),
      ]).then(axios.spread((obj1) => {
        obj1.data != null ? handleMap(obj1.data) : setEquipamento([]);
        setIsLoading(false)
      }));
    } catch (error) {
      setErrorMessage("ERRO");
    }
  }

  const handleMap = (param) => {
    resp = []
    data = []
    arrEquip = []
    arraySet = []

    for (let i = 0; i < param.length; i++) {
      for (let j = 0; j < param[i].data.length; j++) {
        const log = param[i].data[j];
        resp.push(log)
        arrEquip.push(log.Sens)
      }
    }
    const arrUnique = [...new Set(arrEquip)]

    for (let j = 0; j < arrUnique.length; j++) {
      var element = arrUnique[j];
      data = resp.filter(i => i.Sens == element)
      arraySet.push(data)
    }
    setValue(arraySet)
  }

  useEffect(() => {
    handleInformation()
  }, [])

  const handleDataFilter = async () => {
    if (dataInicio > dataFim) {
      alert("Insira a 'Data fim' maior que a 'Data início'. ")
      return
    }
    if (dataInicio !== undefined || dataFim !== undefined) {
      try {
        axios.all([
          axios.get(`${process.env.REACT_APP_API_MONITORA}/measurements/dryer/filter/${nsEquipamento}?inicio=${dataInicio}&fim=${dataFim}`),
        ])
          .then(axios.spread((obj1) => {
            obj1.data != null ? handleMap(obj1.data) : setEquipamento([])
            setAmount(obj1.data.length)
            setIsLoading(false)
          }));
      } catch (error) {
        setErrorMessage("ERRO", error);
      }
    } else {
      alert("Insira data e horário do início e fim")
    }
  }

  const handleReport = async (p) => {
    var nsSensor = p
    try {
      window.open(`${url}/logs/sensor/${nsEquipamento}/${nsSensor}`)
    } catch (error) {

    }
  }

  const renderGrafico = (
    <div className="">
      {value.length === 0 ?
        <h2 className="mt-4">Sem dados para esse número de série</h2>
        : value.map((i) => {
          return (
            <div key={i[0].Sens}>
              <Row>
                <Col>
                  <h3 className="text-start mt-3 text-light">{i[0].Sens}</h3>
                </Col>
                <Col>
                  <div className="text-end">
                    <Button variant="contained" color="success" className="col-sm-4 button mt-2" id="submit" onClick={() => { handleReport(i[0].Sens) }} disabled={false}>
                      Baixar Relatório
                    </Button>
                  </div>
                </Col>
              </Row>
              <DryerGrafico equipamento={i} /> <br />
            </div>
          )
        })
      }
    </div>
  );

  return (
    <>
      <Header />
      <Container fluid className="pt-2" style={{ "background": "#539A73" }}>
        <div>
          <Row>
            <div className="col-sm-2">
              <TextField
                style={{ "paddingRight": "5px" }}
                className="mb-3 mt-2 col-sm-12 inputdata"
                autoComplete="off"
                id="numero-buscas"
                label="Número de buscas"
                variant="outlined"
                type="number"
                onChange={(event) => {
                  let params = Number(event.target.value);
                  params === 0 ? setAmount(1) : params > 1000 ? setAmount(1000) : setAmount(params);
                }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={amount}
              />
            </div>

            <div className="col-sm-2">
              <TextField
                style={{ "paddingRight": "5px" }}
                className="mb-3 mt-2 col-sm-12 inputdata"
                autoComplete="off"
                id="numero-serie"
                label="NS Equipamento"
                variant="outlined"
                type="text"
                onChange={(event) => {
                  let nsEquipamentos = (event.target.value).toUpperCase();
                  setNsEquipamento(nsEquipamentos);
                }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={nsEquipamento}
              />
            </div>

            <div className="col-sm-2">
              <Button style={{ "width": "100%" }} variant="contained" color="success" className="button mt-2" id="submit" onClick={() => { handleInformation() }} disabled={false}>
                Buscar
              </Button>
            </div>

            <div className="col-sm-2">
              <TextField
                style={{ "paddingRight": "5px" }}
                className="mb-3 mt-2 col-sm-12 inputdata"
                autoComplete="off"
                id="numero-inicio"
                label="Data início"
                variant="outlined"
                type="datetime-local"
                onChange={(event) => {
                  let dataInicio = (event.target.value)
                  setDataInicio(dataInicio)
                }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={dataInicio}
              />
            </div>

            <div className="col-sm-2">
              <TextField
                style={{ "paddingRight": "5px" }}
                className="mb-3 mt-2 col-sm-12 inputdata"
                autoComplete="off"
                id="numero-fim"
                label="Data fim"
                variant="outlined"
                type="datetime-local"
                onChange={(event) => {
                  let dataFim = (event.target.value)
                  setDataFim(dataFim)
                }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                value={dataFim}
              />
            </div>

            <div className="col-sm-2">
              <Button style={{ "width": "100%" }} variant="contained" color="success" className="button mt-2" id="submit" onClick={() => { handleDataFilter() }} disabled={dataFim && dataInicio ? false : true}>
                Medidas por periodo
              </Button>
            </div>
          </Row>
        </div>
      </Container>

      <Container fluid>
        {isLoading ? <h1>carregando...</h1> : renderGrafico}
        {errorMessage && <div className="error">{errorMessage}</div>}
      </Container>
    </>
  )
}
export default Dryer
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Fragment } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Telemetria from './pages/Telemetria/Telemetria'
import useAuth from './hooks/useAuth'
import Login from './pages/Login/Login'
import Home from './pages/Home/Home';
import Sensores from './pages/Sensores/Sensores';
import RemoutComand from './pages/Rout/RemoutComand';
import Sockets from './pages/Socket/Sockets';
import Muf from './pages/Muf/Muf';

const App = () => {
  const Private = ({ Item }) => {
    const signed = useAuth();
    return signed ? <Item /> : <Login />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path="/home" element={<Private Item={Home} />} />
            <Route exact path="/telemetria" element={<Private Item={Telemetria} />} />
            <Route exact path="/muf" element={<Private Item={Muf} />} />
            <Route exact path="/sensores" element={<Private Item={Sensores} />} />
            <Route exact path="/sockets" element={<Private Item={Sockets} />} />
            <Route exact path="/remoutcomand" element={<Private Item={RemoutComand} />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </div>
  )
}
export default App;
const Errors = {
    "ERRO_01": "FUNIL SENSOR CAPACITIVO NÃO DETECTOU GRÃO",
    "ERRO_02": "FUNIL BORBOLETA NÃO ABRIL",
    "ERRO_03": "FUNIL BORBOLETA NÃO FECHOU",
    "ERRO_04": "FUNIL SENSOR INDUTIVO CAMI NÃO DETECTOU",
    "ERRO_05": "FUNIL HOUVE INTERRUPÇÃO NO FECHAMENTO DA BORBOLETA",
    "ERRO_06": "FUNIL HOUVE INTERRUPÇÃO NA ABERTURA DA BORBOLETA",
    "ERRO_07": "",
    "ERRO_08": "",
    "ERRO_09": "",
    "ERRO_10": "VASSOURA SENSOR INDUTIVO NÃO DETECTA FIM DE CURSO VASSOURA",
    "ERRO_11": "VASSOURA MOTOR NÃO VARRE",
    "ERRO_12": "VASSOURA MOTOR NÃO VOLTOU COM NUMERO DE PASSOS",
    "ERRO_13": "",
    "ERRO_14": "",
    "ERRO_15": "",
    "ERRO_16": "",
    "ERRO_17": "",
    "ERRO_18": "",
    "ERRO_19": "",
    "ERRO_20": "DOSADOR BORBOLETA NÃO ABRIL",
    "ERRO_21": "DOSADOR BORBOLETA NÃO FECHOU",
    "ERRO_22": "DOSADOR SENSOR INDUTIVO NÃO DETECTA PASSAGEM DO CAMI",
    "ERRO_23": "DOSADOR NÃO RECEBEU GRÃOS (VAZIO)",
    "ERRO_24": "DOSADOR AMOSTRA INSUFICIENTE",
    "ERRO_25": "DOSADOR AMOSTRA FORA DA MEDIA",
    "ERRO_26": "DOSADOR HOUVE INTERRUPÇÃO NA ABERTURA DA BORBOLETA",
    "ERRO_27": "DOSADOR HOUVE INTERRUPÇÃO NO FECHAMENTO DA BORBOLETA",
    "ERRO_28": "",
    "ERRO_29": "",
    "ERRO_30": "COPO SENSOR INDUTIVO NÃO DETECTOU",
    "ERRO_31": "COPO MOTOR NÃO ENTROU NA POSIÇÃO DE 0º GRAU",
    "ERRO_32": "COPO MOTOR NÃO ENTROU NA POSIÇÃO DE 90º GRAU",
    "ERRO_33": "COPO MOTOR NÃO ENTROU NA POSIÇÃO DE 180º GRAU",
    "ERRO_34": "COPO NÃO FEZ LEITURA DE HUMIDADE",
    "ERRO_35": "COPO NÃO FEZ LEITURA DE PH",
    "ERRO_36": "",
    "ERRO_37": "",
    "ERRO_38": "",
    "ERRO_39": "",
    "ERRO_40": "MEMORY ERROR",
    "ERRO_41": "HARDWARE ERROR",
    "ERRO_42": "SENSOR DE TEMP E HUMIDADE AMBIENTE SEM LEITURA",
    "ERRO_43": "MOTOR DC ERROR",
    "ERRO_44": "",
    "ERRO_45": "",
    "ERRO_46": "",
    "ERRO_47": "",
    "ERRO_48": "",
    "ERRO_49": "",
    "ERRO_50": "",
    "ERRO_51": "",
    "ERRO_52": "",
    "ERRO_53": "",
    "ERRO_54": "",
    "ERRO_55": "",
    "ERRO_56": ""
}
export default Errors;
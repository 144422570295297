import { Button, Form, Container, Row, Col, Card } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Api from '../../services/Api'
import getToken from '../../hooks/getToken';
import { useNavigate } from "react-router-dom";
import { VscDebugDisconnect } from "react-icons/vsc";

const Sockets = () => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    localStorage.setItem("equipamento-socket", e.target.value);
    setInputValue(e.target.value);
  };

  const handleOptionClick = (value) => {
    localStorage.setItem("equipamento-socket", value);
    setInputValue(value);
  };

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleEquipment = async () => {
    try {
      var data = await Api.get(`/connection/status`, { "headers": { "token": getToken() } });
      if (data.status === 200) {
        var res = data.data.map(data => data.serialnumber ? data.serialnumber : '').filter(data => data.includes("SEMT") || data.includes("SHTM"));
        res.length ? setOptions(res.sort(compararCodigos)) : setOptions(["Nenhum equipamento Online"]);
      }
    } catch (error) {
      alert(error.message)
    }
  }

  function compararCodigos(a, b) {
    const numA = parseInt(a.match(/\d+/)[0], 10);
    const numB = parseInt(b.match(/\d+/)[0], 10);
    if (numA === numB) {
      return a.localeCompare(b);
    }
    return numA - numB;
  }

  const handleRun = () => {
    if (inputValue) {
      navigate('/remoutcomand')
    } else {
      alert('Escolha um equipamento.')
    }
  }

  useEffect(() => {
    handleEquipment();
    setInterval(handleEquipment, 60000);
  }, [])

  return (
    <>
      <Header />
      <Container fluid className="pt-2">
        <Card bg="dark" border="success" className="p-4">
          <Row xl={12}>
            <Form.Label>Filtro</Form.Label>
            <Col xl={8}>
              <Form.Control
                className="mb-2 pt-2 pb-2"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Digite para buscar..."
              />
              <div className="list-equip">
                {filteredOptions && filteredOptions.map((option, index) => (
                  <p className="mb-0 mt-2 list-equipments" key={index} onClick={() => handleOptionClick(option)}>
                    {option}
                  </p>
                ))}
              </div>
            </Col>
            <Col xl={4}>
              <Button
                className="w-100"
                variant="success"
                disabled={false}
                onClick={() => { handleRun() }}
              >
                {'Conectar '} <VscDebugDisconnect style={{ "fontSize": 30 }} />
              </Button>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  )
}
export default Sockets; 
import React, { useEffect, useState } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import Errors from "../../../utilis/error";

const Equipament = (props) => {
  const {
    serial,
    data,
    handleCamiFunil,
    handleCamiDosador,
    handleCopo,
    handleVass
  } = props;

  const [img, setImg] = useState("0-0-0-90")

  var red = 'red';
  var green = 'green';

  var sens1 = data.S_Funil === 1 ? green : red
  var sens2 = data.P_Funil === true ? green : red
  var sens3 = data.SVass === true ? green : red
  var sens4 = data.P_Dosad === true ? green : red
  var sens5 = data.SCopo_FC === true ? green : red
  var hsign = data.HSign ? green : red
  var dc_rosca = data.DC_Rosca ? green : red

  var PVass = data.PVass === 0 ? 0 : data.PVass === 90 ? 90 : 0
  var P_Funil = data.P_Funil ? 1 : 0
  var P_Dosad = data.P_Dosad ? 1 : 0
  var PCopo = data.PCopo === 0 ? 0 : data.PCopo === 90 ? 90 : data.PCopo === 180 ? 180 : 90

  const updateImg = async () => {
    var image = `${PVass}-${P_Funil}-${P_Dosad}-${PCopo}`
    if (image === "90-1-0-90" || image === "90-1-1-90" || image === "90-1-0-180" || image === "90-1-1-180") {
      image = "0-0-0-90"
    }
    setImg(image)
  }

  useEffect(() => {
    updateImg()
  }, [data])

  return (
    <Row>
      {!serial.includes("SHTM") ?
        <Col xl={9} className="text-center mt-3">
          <Row xs={12}>
            <Col xs={1}>
              <div className="sensores" style={{ "background": red }}>
              </div>
            </Col>
            <Col xs={11} className="status" style={{ 'width': '150px' }}>
              Inativo|Fechado
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <div className="sensores" style={{ "background": green }}>
              </div>
            </Col>
            <Col xs={11} className="status" style={{ 'width': '150px' }}>
              Ativo|Aberto
            </Col>
          </Row>

          <div style={{ "background": `url('../images/silo/${img}.png')` }} className="div-img">

            <div title="Sensor do Funil" className="sens-1" style={{ "background": sens1 }}>
              <strong>1</strong>
            </div>

            <div title="Porta funil" className="sens-2">
              <Button
                style={{ "width": 180 }}
                variant={data.P_Funil ? 'success' : 'danger'}
                disabled={data.DebMode ? false : true}
                onClick={() => {
                  data.P_Funil ? handleCamiFunil(0) : handleCamiFunil(1)
                }}
                size="sm"
              >
                {data.P_Funil ? 'Came Funil Aberto' : 'Came Funil Fechado'}
              </Button>
            </div>

            <div className="sens-3">
              <Button
                style={{ "width": 180 }}
                variant={'success'}
                disabled={data.DebMode ? false : true}
                onClick={() => {
                  data.PVass === 0 ? handleVass(90) : handleVass(0)
                }}
                size="sm"
              >
                {typeof (data.PVass) !== "undefined" ? `Vassoura ${data.PVass} °` : "Vassoura"}
              </Button>
            </div>

            <div className="sens-4">
              <Button
                style={{ "width": 180 }}
                variant={data.P_Dosad ? 'success' : 'danger'}
                disabled={data.DebMode ? false : true}
                onClick={() => {
                  data.P_Dosad ? handleCamiDosador(0) : handleCamiDosador(1)
                }}
                size="sm"
              >
                {data.P_Dosad ? 'Came Dosador Aberto' : 'Came Dosador Fechado'}
              </Button>
            </div>

            <div className="sens-5">
              <Button
                style={{ "width": 180 }}
                variant={'success'}
                disabled={data.DebMode ? false : true}
                onClick={() => {
                  data.PCopo === 0 ? handleCopo(90) : data.PCopo === 90 ? handleCopo(180) : data.PCopo === 180 ? handleCopo(0) : handleCopo(0)
                }}
                size="sm"
              >
                {typeof (data.PCopo) !== "undefined" ? `Copo ${data.PCopo} °` : "Copo"}
              </Button>
            </div>

            <div className="HSign" style={{ "background": hsign }}>
              <strong>Redler {data.HSign ? "Cheio" : "Vazio"}</strong>
            </div>

            <div className="DC_Rosca" style={{ "background": dc_rosca }}>
              <strong>Rosca {data.DC_Rosca ? "Ativa" : "Inativa"}</strong>
            </div>

            <div className="HSignImg" style={{ "background": hsign }}>
              <img className="ImgHSign" src="../images/hsign.png" alt="Rosca do Redler" />
            </div>

            {data.UmidaAmb ?
              <div className="UmidaAmb" style={{ "background": '#e9ecef' }}>
                <h6>Umidade ambiente</h6><strong>{data.UmidaAmb}</strong>
              </div>
              : null}
            {data.TempAmb ?
              <div className="TempAmb" style={{ "background": '#e9ecef' }}>
                <h6>Temperatura ambiente</h6><strong>{data.TempAmb} °C</strong>
              </div>
              : null}

            {data.pcheio ?
              <div className="sens-7" style={{ "background": "#e9ecef" }}>
                <strong>{data.pcheio}</strong>
              </div>
              : null}
          </div>

        </Col>
        :
        <Col xl={9} className="text-center mt-3">
          <h5>Sersor: {serial}</h5>
        </Col>
      }
      <Col xl={3}>
        <Col className="mb-3">
          <Form.Label className="mb-0" htmlFor="serial">Número de série</Form.Label>
          <Form.Control
            size="sm"
            type="serial"
            id="serial"
            aria-describedby="serial"
            value={data.ns ? data.ns : ''}
            disabled
          />
        </Col>

        {data.ERROR ?
          <Col className="mb-3">
            <Form.Label className="mb-0" htmlFor="serial">Lista de erros</Form.Label>
            <Form.Select
              aria-label="Default select example">
              {console.log(Errors.ERRO_01)}
              {data.ERROR &&
                data.ERROR.map(item => (
                  <option key={item} value={item}>{Errors[item]}</option>
                ))}
            </Form.Select>
          </Col>
          : null}

        <Col className="mt-3">
          <Form.Label className="mb-0" htmlFor="serial">Debug Mode</Form.Label>
          <Form.Control
            size="sm"
            type="serial"
            id="serial"
            aria-describedby="serial"
            value={data.DebMode ? "Ativo" : 'Inativo'}
            disabled
          />
        </Col>

        <Col className="mt-3">
          <Form.Label className="mb-0" htmlFor="serial">Monitoramento</Form.Label>
          <Form.Control
            size="sm"
            type="serial"
            id="serial"
            aria-describedby="serial"
            value={data.Mont_Dbg ? "Ativo" : 'Inativo'}
            disabled
          />
        </Col>

        <Col className="mt-3">
          <Form.Label className="mb-0" htmlFor="serial">Celula de Carga</Form.Label>
          <Form.Control
            size="sm"
            type="serial"
            id={data.CelCarga}
            aria-describedby="serial"
            value={data.CelCarga ? data.CelCarga : 'Sem status'}
            disabled
          />
        </Col>

        <Col className="mt-3">
          <Form.Label className="mb-0" htmlFor="serial">Calibração de PH</Form.Label>
          <Form.Control
            size="sm"
            type="serial"
            id="serial"
            aria-describedby="serial"
            value={data.calPh_g}
            disabled
          />
        </Col>

        <Col className="mt-3">
          <Form.Label className="mb-0" htmlFor="serial">Codigo</Form.Label>
          <Form.Control
            size="sm"
            type="serial"
            id="serial"
            aria-describedby="serial"
            value={data.code}
            disabled
          />
        </Col>

        <Col className="mt-3">
          <Form.Label className="mb-0" htmlFor="serial">Peso Relativo</Form.Label>
          <Form.Control
            size="sm"
            type="serial"
            id="serial"
            aria-describedby="serial"
            value={data.pRelKit}
            disabled
          />
        </Col>

        <Col className="mt-3">
          <Form.Label className="mb-0" htmlFor="serial">Temperatura do Grão</Form.Label>
          <Form.Control
            size="sm"
            type="serial"
            id="serial"
            aria-describedby="serial"
            value={data.tempC}
            disabled
          />
        </Col>

        <Col className="mt-3">
          <Form.Label className="mb-0" htmlFor="serial">ID do Copo</Form.Label>
          <Form.Control
            size="sm"
            type="serial"
            id="serial"
            aria-describedby="serial"
            value={data.idCopo}
            disabled
          />
        </Col>
      </Col>
    </Row>
  )
}
export default Equipament;
import React, { useEffect, useState } from "react";
import Chart from 'chart.js/auto';
import dateFormat from "dateformat";
import { Line } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const TelemetriaGrafico = (props) => {
  const { equipamento, data } = props;

  const [humidity, setHumidity] = useState({
    labels: [],
    datasets: []
  })
  const [optionshumidity, setOptionshumidity] = useState(null)


  const [peso, setPeso] = useState({
    labels: [],
    datasets: []
  })
  const [optionspeso, setOptionspeso] = useState(null)


  const [temperatura, setTemperatura] = useState({
    labels: [],
    datasets: []
  })
  const [optionstemperatura, setOptionstemperatura] = useState(null)

  var mediahumidity = 0
  var mediapeso = 0
  var mediatemperatura = 0

  for (let i = 0; i < equipamento.length; i++) {
    mediahumidity += parseFloat(equipamento[i].humidity)
  } mediahumidity = mediahumidity / equipamento.length;

  for (let i = 0; i < equipamento.length; i++) {
    mediapeso += parseFloat(equipamento[i].peso)
  } mediapeso = mediapeso / equipamento.length;

  for (let i = 0; i < equipamento.length; i++) {
    mediatemperatura += parseFloat(equipamento[i].temperature)
  } mediatemperatura = mediatemperatura / equipamento.length;

  useEffect(() => {
    let colorB = 'rgba(27, 93, 67, 0.8)'

    let labels = equipamento.map(data => dateFormat(data.date, "UTC:dd/mm' 'HH:MM:ss"));
    const humidityLabel = {
      labels,
      datasets: [{
        labels,
        label: 'UMIDADE',
        data: equipamento.map((equipamento) => (parseFloat(equipamento.humidity).toFixed(1))),
        borderColor: colorB,
        //backgroundColor: backgroundGraphics,
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          display: 'auto',
          align: 'end',
          font: {
            weight: 'bold',
            size: 12,
          }
        },
      },
      {
        label: `Média ${mediahumidity ? mediahumidity.toFixed(1) : 0}`,
        data: equipamento.map((equipamento) => { return mediahumidity }),
        borderColor: "#BEE667",
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: false,
        datalabels: {
          display: false,
        }
      },
      ]
    }
    setHumidity(humidityLabel)

    let number = equipamento.map((equipamento) => (parseFloat(equipamento.humidity).toFixed(1)))
    let maxValue = Math.max(...number)
    let minValue = Math.min(...number)

    const optionsHumididy = {
      scales: {
        y: {
          min: minValue - 2,
          max: maxValue + 3,
          stepSize: 5,
        },
        x: {},
      }
    };
    setOptionshumidity(optionsHumididy)

    //----------------------------------------------------------------

    labels = equipamento.map(data => dateFormat(data.date, "UTC:dd/mm' 'HH:MM:ss"));
    const pesoLabel = {
      labels,
      datasets: [{
        labels,
        label: 'PESO EM GRAMAS',
        data: equipamento.map((equipamento) => (parseFloat(equipamento.peso).toFixed(1))),
        borderColor: colorB,
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          labels: {
            title: {
              //color: "red",
            }
          },
          display: 'auto',
          align: 'end',
          font: {
            weight: 'bold',
            size: 12,
          }
        },
      },
      {
        label: `Média ${mediapeso ? mediapeso.toFixed(1) : 0}g`,
        data: equipamento.map((equipamento) => { return mediapeso }),
        borderColor: "#BEE667",
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: false,
        datalabels: {
          display: false,
        }
      },
      ]
    }
    setPeso(pesoLabel)

    number = equipamento.map((equipamento) => (parseFloat(equipamento.peso).toFixed(1)))
    maxValue = Math.max(...number)
    minValue = Math.min(...number)

    const optionspeso = {
      scales: {
        y: {
          min: minValue - 2,
          max: maxValue + 3,
          stepSize: 5,
        },
        x: {},
      }
    };
    setOptionspeso(optionspeso)

    //----------------------------------------------------------------

    labels = equipamento.map(data => dateFormat(data.date, "UTC:dd/mm' 'HH:MM:ss"));
    const temperaturaLabel = {
      labels,
      datasets: [{
        labels,
        label: 'TEMPERATURA',
        data: equipamento.map((equipamento) => (parseFloat(equipamento.temperature).toFixed(1))),
        borderColor: colorB,
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          display: 'auto',
          align: 'end',
          font: {
            weight: 'bold',
            size: 12,
          }
        },
      },
      {
        label: `Média ${mediatemperatura ? mediatemperatura.toFixed(1) : 0}°C`,
        data: equipamento.map((equipamento) => { return mediatemperatura }),
        borderColor: "#BEE667",
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: false,
        datalabels: {
          display: false,
        }
      },
      ]
    }
    setTemperatura(temperaturaLabel)

    number = equipamento.map((equipamento) => (parseFloat(equipamento.temperature).toFixed(1)))
    maxValue = Math.max(...number)
    minValue = Math.min(...number)

    const optionsPeso = {
      scales: {
        y: {
          min: minValue - 2,
          max: maxValue + 3,
          stepSize: 5,
        },
        x: {},
      }
    };
    setOptionstemperatura(optionsPeso)

  }, [equipamento, data])

  const plugins = {
    afterDraw: function (chart) {
      if (chart.data.datasets.length > 0) {
        if (chart.data.datasets[0].data.length === 0) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (height / 160).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "top";
          var text = "Nenhuma Informação Disponível",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }
    },
  }

  return (
    <Card>
      {
        humidity && optionshumidity &&
        <Line options={optionshumidity} data={humidity} plugins={[plugins, ChartDataLabels]} height={40} />
      }
      <div style={{ "padding": "0px", "background": "#DCE7E9" }}>
        <div style={{ "heigth": "10px", "background": "#539a73", "padding": "2px" }}>
        </div>
      </div>
      {
        peso && optionspeso &&
        <Line options={optionspeso} data={peso} plugins={[plugins, ChartDataLabels]} height={40} />
      }
      <div style={{ "padding": "0px", "background": "#DCE7E9" }}>
        <div style={{ "heigth": "10px", "background": "#539a73", "padding": "2px" }}>
        </div>
      </div>
      {
        temperatura && optionstemperatura &&
        <Line options={optionstemperatura} data={temperatura} plugins={[plugins, ChartDataLabels]} height={40} />
      }
    </Card>
  )
}
export default TelemetriaGrafico

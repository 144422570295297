import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { Navbar, Container, Nav, Form, Button } from 'react-bootstrap';

const NavBar = () => {
  const navigate = useNavigate();

  const usuario = JSON.parse(localStorage.getItem('usuario'));
  const expand = false

  const email = usuario && usuario.email ? usuario.email : null;

  const handleLogout = () => {
    localStorage.clear()
    return navigate('/login')
  }

  const handleDeleteEquip = () => {
    localStorage.removeItem("equipamento-socket");
  }

  return (
    <Navbar bg="dark" data-bs-theme="dark">
      <Container fluid>
        <Nav className="me-auto">
          <Link to="/telemetria" className="nav-bar">Telemetria</Link>
          <Link to="/muf" className="nav-bar">Muf</Link>
          <Link to="/sensores" className="nav-bar">Sensores</Link>
          {email === "engenharia10@locsolution.com" ||
            email === "engenharia4@locsolution.com" ||
            email === "engenharia2@locsolution.com" ||
            email === "engenharia1@locsolution.com" ||
            email === "engenharia6@locsolution.com" ?
            <Link to="/sockets" onClick={handleDeleteEquip} className="nav-bar"> Socket</Link>
            : null}
        </Nav>
        <Form className="d-flex">
          <Button onClick={() => {
            handleLogout()
          }} variant="outline-success" size="sm">Sair</Button>
        </Form>
      </Container>
    </Navbar>
  )
}
export default NavBar;

import React, { useEffect, useState } from "react";
import axios from 'axios';
import Api from '../../services/Api'
import TelemetriaGrafico from "./components/TelemetriaGrafico";
import TextField from '@mui/material/TextField';
import Header from '../../components/Header/Header'
import { Container, Row, Button, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";

const Telemetria = (props) => {
  var [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [equipamento, setEquipamento] = useState([])
  const [param, setParam] = useState(50)
  const [nsEquipamento, setNsEquipamento] = useState("SEMT-00001")
  const [dataInicio, setDataInicio] = useState()
  const [dataFim, setDataFim] = useState()
  const [countNs, setCountNs] = useState(0)

  const handleInformation = async () => {
    setIsLoading(true)
    if (param > 1000) {
      alert("Consulta máxima excedida. 1000 ")
      setIsLoading(false)
      setParam(1000)
      return
    }
    try {
      var data = await Api.get(`/measurements/byEquip/lastn/${nsEquipamento}/${param}`)
      if (data.status === 200) {
        let listaRevertida = [...data.data].reverse();
        setEquipamento(listaRevertida);
        setIsLoading(false)
      }
    } catch (error) {
      setErrorMessage("ERRO");
      setIsLoading(false);
    }
  }

  const handleCount = async () => {
    try {
      await axios.all([
        axios.get(`${process.env.REACT_APP_API_MONITORA}/measurements/count/${nsEquipamento}`),
      ])
        .then(axios.spread((obj1) => {
          setCountNs(obj1.data)
        }));
    } catch (error) {
      setErrorMessage("ERRO");
    }
  }

  useEffect(() => {
    if (nsEquipamento.length === 10) {
      handleInformation()
      handleCount()
    } else {
      return
    }
    const intervalId = setInterval(() => {
      handleInformation()
      handleCount()
    }, 120000)
    return () => clearInterval(intervalId);
  }, [param, nsEquipamento])

  const handleData = async () => {
    if (dataInicio !== undefined || dataFim !== undefined) {
      window.open(`${process.env.REACT_APP_API_MONITORA}/logs/filter/${nsEquipamento}?inicio=${dataInicio}&fim=${dataFim}`, "_blank")
    } else {
      alert("Insira data e horário do início e fim")
    }
  }

  const handleDataAll = async () => {
    window.open(`${process.env.REACT_APP_API_MONITORA}/logs/user/${nsEquipamento}`, "_blank")
  }

  return (
    <>
      <Header />
      <Container fluid className="pt-2 mb-2" style={{ "background": "#539A73" }}>

        <div>
          <Row>
            <div className="col-sm-2">
              <TextField
                style={{ "paddingRight": "5px" }}
                className="mb-3 mt-2 col-sm-12 inputdata"
                autoComplete="off"
                id="outlined-disabled"
                variant="outlined"
                type="TextField"
                size="small"
                label={countNs.n ? "Total de medições" : "Sem medições"}
                value={countNs.n}
                InputLabelProps={{
                  "shrink": true,
                }}
                disabled
              />
            </div>
            <div className="col-sm-2">
              <TextField
                style={{ "paddingRight": "5px" }}
                className="mb-3 mt-2 col-sm-12 inputdata"
                autoComplete="off"
                id="numero-buscas"
                label="Número de buscas"
                variant="outlined"
                type="number"
                onChange={(event) => {
                  let params = Number(event.target.value);
                  params === 0 ? setParam(1) : setParam(params);
                }}
                size="small"
                InputLabelProps={{
                  "shrink": true,
                }}
                value={param}
              />
            </div>

            <div className="col-sm-2">
              <TextField
                style={{ "paddingRight": "5px" }}
                className="mb-3 mt-2 col-sm-12 inputdata"
                autoComplete="off"
                id="numero-serie"
                label="NS Equipamento"
                variant="outlined"
                type="text"
                onChange={(event) => {
                  let nsEquipamentos = (event.target.value).toUpperCase();
                  setNsEquipamento(nsEquipamentos);
                }}
                size="small"
                InputLabelProps={{
                  "shrink": true,
                }}
                value={nsEquipamento}
              />
            </div>

            <div className="col-sm-2">
              <TextField
                style={{ "paddingRight": "5px" }}
                className="mb-3 mt-2 col-sm-12 inputdata"
                autoComplete="off"
                id="numero-inicio"
                label="Data início"
                variant="outlined"
                type="datetime-local"
                onChange={(event) => {
                  let dataInicio = (event.target.value)
                  setDataInicio(dataInicio)
                }}
                size="small"
                InputLabelProps={{
                  "shrink": true,
                }}
                value={dataInicio}
              />
            </div>

            <div className="col-sm-2">
              <TextField
                style={{ "paddingRight": "5px" }}
                className="mb-3 mt-2 col-sm-12 inputdata"
                autoComplete="off"
                id="numero-fim"
                label="Data fim"
                variant="outlined"
                type="datetime-local"
                onChange={(event) => {
                  let dataFim = (event.target.value)
                  setDataFim(dataFim)
                }}
                size="small"
                InputLabelProps={{
                  "shrink": true,
                }}
                value={dataFim}
              />
            </div>

            <div className="col-sm-2">
              <DropdownButton
                className="mt-2"
                as={ButtonGroup}
                style={{ "width": "100%" }}
                title="Relatório"
                id="bg-vertical-dropdown-1"
              >
                <Dropdown.Item eventKey="1" onClick={() => { handleData() }} disabled={isLoading}>Relatório Por Data</Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={() => { handleDataAll() }} disabled={isLoading}>Relatório Completo</Dropdown.Item>
              </DropdownButton>
            </div>
          </Row>
        </div>
      </Container>
      <Container fluid>
        {isLoading && <Loader />}
        {equipamento && equipamento.length ?
          <h5 className='card-h6-barchart' style={{ "marginBottom": "0px" }}>EQUIPAMENTO: {nsEquipamento} CURVA: {equipamento[equipamento.length - 1].grain}</h5>
          : null}
        {equipamento && equipamento.length ?
          <TelemetriaGrafico equipamento={equipamento} />
          : null}
        {errorMessage && <div className="error">{errorMessage}</div>}
      </Container>
    </>
  )
}
export default Telemetria
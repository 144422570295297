
import React from "react";
import Header from "../../components/Header/Header";
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Canvas } from '@react-three/fiber'
import Box from "../Box/Box";

const Home = () => {
  const navigate = useNavigate()

  const handleTelemetria = () => {
    navigate('/telemetria');
  }

  const handleSersores = () => {
    navigate('/sensores');
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
            <Button onClick={handleTelemetria} className="mt-4" variant="success">Telemetria</Button>
          </Col>
          <Col>
            <Button onClick={handleSersores} className="mt-4" variant="success">Sensores</Button>
          </Col>
          <Col>

          </Col>
        </Row>

        <Canvas>
          <Box />
        </Canvas>

      </Container>
    </>
  )
}
export default Home


import { Button, Row, Col, Container, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Equipament from "./components/Equipament";
import TextField from '@mui/material/TextField';
import Command from "../../utilis/commands";
import Bool from "../../utilis/bool";
import { useNavigate } from 'react-router-dom'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { io } from 'socket.io-client';

const RemoutComand = (props) => {

  const socket = io(process.env.REACT_APP_API_MONITORA, {
    timeout: 60000,
    query: {
      transport: 'polling',
    }
  });

  const navigate = useNavigate()

  const serial = (localStorage.getItem('equipamento-socket'));

  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [key, setKey] = useState('Debug')
  const [status, setStatus] = useState('')

  var [boole, setBoole] = useState(1)
  var [isConnected, setIsConnected] = useState(false);

  var comand = {
    "serialnumber": serial,
    "command": {
      [key]: boole
    }
  }

  const handleJson = () => {
    if (!key || !boole || boole === "Valor") {
      alert('Insira um comando!')
      return
    }

    var chaveValor = {
      [key]: boole === "true" ? boole = 1
        : boole === "false" ? boole = 0
          : boole === "0" ? boole = 0
            : boole === "90" ? boole = 90
              : boole === "180" ? boole = 180
                : boole = Number(boole)
    }

    delete comand.command
    comand.command = chaveValor
    console.log(comand);
    socket.emit('command', JSON.stringify(comand));
  }


  const onConnect = () => {
    var comand = {
      "serialnumber": serial,
      "command": {
        "Debug": 1
      }
    }
    console.log(comand);
    socket.emit('command', JSON.stringify(comand))
    setIsConnected(socket.connected)
  }

  const onDesconnect = async () => {
    localStorage.removeItem("equipamento-socket");
    var comand = {
      "serialnumber": serial,
      "command": {
        "Debug_Mode": 0
      }
    }
    console.log(comand);
    socket.emit('command', JSON.stringify(comand))
    socket.disconnect();
    navigate('/sockets')
  }

  useEffect(() => {
    if (key === "densEsf") {
      setBoole('')
    }
  }, [key])

  useEffect(() => {
    setTimeout(onConnect, 500);
  }, [])

  useEffect(() => {
    socket.on(serial, (message) => {
      console.log("Dados recebidos do equipamento:", message);
      setData(JSON.parse(message));
    });
  }, [])

  const handleDebMod = (e) => {
    var comand = {
      "serialnumber": serial,
      "command": {
        "Debug_Mode": e
      }
    }
    console.log(JSON.stringify(comand));
    socket.emit('command', JSON.stringify(comand))
  }

  const handleCamiDosador = (e) => {
    var comand = {
      "serialnumber": serial,
      "command": {
        "DC_Cami_Dosador": e
      }
    }
    console.log(comand);
    socket.emit('command', JSON.stringify(comand))
  }

  const handleCamiFunil = (e) => {
    var comand = {
      "serialnumber": serial,
      "command": {
        "DC_Cami_Funil": e
      }
    }
    console.log(comand);
    socket.emit('command', JSON.stringify(comand))
  }

  const handleVass = (e) => {
    var comand = {
      "serialnumber": serial,
      "command": {
        "Pos_Vass": e
      }
    }
    console.log(comand);
    socket.emit('command', JSON.stringify(comand))
  }

  const handleCopo = (e) => {
    var comand = {
      "serialnumber": serial,
      "command": {
        "Pos_Copo": e
      }
    }
    console.log(comand);
    socket.emit('command', JSON.stringify(comand))
  }

  const handleReset = () => {
    var comand = {
      "serialnumber": serial,
      "command": {
        "Reset": 1
      }
    }
    console.log(comand);
    socket.emit('command', JSON.stringify(comand))
  }

  const handleMont = () => {
    var comand = {
      "serialnumber": serial,
      "command": {
        "Mont_Dbg": 1
      }
    }
    console.log(comand);
    socket.emit('command', JSON.stringify(comand))
  }

  const handleRunMode = (e) => {
    var comand = {
      "serialnumber": serial,
      "command": {
        "RunMode": e
      }
    }
    console.log(comand);
    socket.emit('command', JSON.stringify(comand))
  }


  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col xl={2}>
            <Row className="mt-2" style={{ marginLeft: 0, marginRight: 0 }}>
              <Button
                className="w-100"
                variant="outline-danger"
                disabled={false}
                onClick={() => { onDesconnect() }}
                size="sm"
              >
                {'Desconectar | Sair'}
              </Button>

              <Form.Control
                className="mt-2"
                size="sm"
                type="serial"
                id="serial"
                aria-describedby="serial"
                value={serial ? serial : 0}
                disabled
              />

              <Button
                className="w-100 mt-2"
                variant={isConnected ? 'success' : 'danger'}
                disabled={true}
                size="sm"
              >
                {isConnected ? 'Conectado' : 'Desconectado'}
              </Button>
              <hr />
              {!isConnected &&
                <Button
                  className="w-100 mt-2"
                  variant="secondary"
                  disabled={false}
                  onClick={() => { window.location.reload(true) }}
                  size="sm"
                >
                  {'Reconectar'}
                </Button>
              }
            </Row>

            {isConnected ?
              <>
                <DropdownButton
                  className="w-100 mt-2"
                  as={ButtonGroup}
                  key={'Warning'}
                  variant="outline-warning"
                  title={'Mais Comandos'}
                  size="sm"
                  disabled={data.DebMode ? false : true}
                >
                  <Dropdown.Item onClick={() => { handleRunMode(1) }}>Ignorar Sensor Headler</Dropdown.Item>
                  <Dropdown.Item onClick={() => { handleRunMode(2) }}>Ignorar Sensor Headler e Sensor Capacitivo Funil</Dropdown.Item>
                  <Dropdown.Item onClick={() => { handleRunMode(3) }}>Bloquear o Equipamento</Dropdown.Item>
                  <hr />
                  <Dropdown.Item onClick={() => { handleRunMode(0) }}>Restaurar Configuração Padrão</Dropdown.Item>
                </DropdownButton>

                <Row style={{ marginLeft: 0, marginRight: 0 }}>
                  <Button
                    className="w-100 mt-2"
                    variant="outline-danger"
                    disabled={!boole}
                    onClick={() => { handleReset() }}
                    size="sm"
                  >
                    {'Reiniciar Equipamento'}
                  </Button>
                  <Button
                    className="w-100 mt-2"
                    variant={data.Mont_Dbg ? "outline-danger" : "outline-warning"}
                    disabled={!boole}
                    onClick={() => { handleMont() }}
                    size="sm"
                  >
                    {data.Mont_Dbg ? 'Destivar Monitoramento' : 'Ativar Monitoramento'}
                  </Button>
                  <hr />

                  <Form.Select onChange={(event) => {
                    var key = event.target.value;
                    setKey(key)
                  }}
                    aria-label="Default select example"
                    className="mt-2"
                  >
                    <option> Comando </option>
                    {Command &&
                      Command.map(item => (
                        <option key={item.id} value={item.command}>{item.name}</option>
                      ))}
                  </Form.Select>

                  {key === "Interval" ||
                    key === "densEsf" ||
                    key === "Salve_ns" ||
                    key === "ajCopo" ?

                    <TextField
                      style={{ "background": "#fff" }}
                      className="inputdata w-100 mt-2"
                      autoComplete="off"
                      id="outlined-disabled"
                      variant="outlined"
                      type="TextField"
                      size="small"
                      onChange={(event) => {
                        setBoole(event.target.value)
                      }}
                      value={boole}
                    />
                    : <Form.Select
                      onChange={(event) => {
                        var boole = event.target.value;
                        setBoole(boole)
                      }}
                      aria-label="Default select example"
                      className="mt-2"
                    >
                      <option> Valor </option>
                      {Bool &&
                        Bool.map(item => (
                          <option disabled={!key} key={item.id} value={item.bool}> {item.bool} </option>
                        ))}
                    </Form.Select>
                  }

                  <Button
                    className="w-100 mt-2"
                    variant="primary"
                    disabled={!boole}
                    onClick={!isLoading ? (param) => handleJson() : null}
                    size="sm"
                  >
                    {'Enviar Comando'}
                  </Button>

                  <hr />

                  <TextField
                    style={{ "background": "#fff" }}
                    className="inputdata w-100 mt-2"
                    autoComplete="off"
                    id="outlined-disabled"
                    variant="outlined"
                    type="TextField"
                    size="small"
                    onChange={(event) => {
                      setKey(event.target.value)
                    }}
                    value={key}
                  />

                  <TextField
                    style={{ "background": "#fff" }}
                    className="inputdata w-100 mt-2"
                    autoComplete="off"
                    id="outlined-disabled"
                    variant="outlined"
                    type="TextField"
                    size="small"
                    onChange={(event) => {
                      setBoole(event.target.value)
                    }}
                    value={boole}
                  />

                  <Button
                    className="w-100 mt-2"
                    variant="primary"
                    disabled={!boole}
                    onClick={!isLoading ? (param) => handleJson() : null}
                    size="sm"
                  >
                    {'Enviar Comando Personalizado'}
                  </Button>

                  <hr />
                  <Button
                    className="w-100 mt-2"
                    variant={data.DebMode === 1 ? 'outline-danger' : 'danger'}
                    disabled={false}
                    onClick={() => {
                      data.DebMode === 1 ? handleDebMod(0) : handleDebMod(1)
                    }}
                    size="sm"
                  >
                    {data.DebMode === 1 ? 'Desativar Debug Mode' : 'Ativar Debug Mode'}
                  </Button>

                  <hr />

                  {data.DebMode ?
                    <Button
                      className="w-100 mt-2"
                      variant={data.P_Funil ? 'success' : 'danger'}
                      disabled={false}
                      onClick={() => {
                        data.P_Funil ? handleCamiFunil(0) : handleCamiFunil(1)
                      }}
                      size="sm"
                    >
                      {data.P_Funil ? 'Came Funil Aberto' : 'Came Funil Fechado'}
                    </Button>
                    : null}

                  {data.DebMode ?
                    <Button
                      className="w-100 mt-2"
                      variant={data.P_Dosad ? 'success' : 'danger'}
                      disabled={false}
                      onClick={() => {
                        data.P_Dosad ? handleCamiDosador(0) : handleCamiDosador(1)
                      }}
                      size="sm"
                    >
                      {data.P_Dosad ? 'Came Dosador Aberto' : 'Came Dosador Fechado'}
                    </Button>
                    : null}

                  {data.DebMode ?
                    <Button
                      className="w-100 mt-2"
                      variant={'success'}
                      disabled={false}
                      onClick={() => {
                        data.PVass === 0 ? handleVass(90) : handleVass(0)
                      }}
                      size="sm"
                    >
                      {typeof (data.PVass) !== "undefined" ? `Vassoura ${data.PVass} °` : "Vassoura"}
                    </Button>
                    : null}

                  {data.DebMode ?
                    <Button
                      className="w-100 mt-2"
                      variant={'success'}
                      disabled={false}
                      onClick={() => {
                        data.PCopo === 0 ? handleCopo(90) : data.PCopo === 90 ? handleCopo(180) : data.PCopo === 180 ? handleCopo(0) : handleCopo(0)
                      }}
                      size="sm"
                    >
                      {typeof (data.PCopo) !== "undefined" ? `Copo ${data.PCopo} °` : "Copo"}
                    </Button>
                    : null}
                </Row>
              </>
              : null}

          </Col>
          <Col xl={10}>
            <h5 className="pt-2">{status && status.status ? status.status : null}</h5>
            {data && data.resp ?
              <h5 className="pt-2">{data && data.resp ? data.resp : null}</h5>
              : null}
            <Container fluid className="pb-3">
              {data && <Equipament
                serial={serial}
                data={data}
                handleCamiFunil={handleCamiFunil}
                handleCamiDosador={handleCamiDosador}
                handleCopo={handleCopo}
                handleVass={handleVass}
              />}
            </Container>

          </Col>
        </Row>
      </Container>
    </>
  )
}
export default RemoutComand

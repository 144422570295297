import React, { useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'

const Box = (props) => {
  const ref = useRef()

  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)

  useFrame(() => (ref.current.rotation.x = ref.current.rotation.y += 0.01))
  // useFrame(() => (ref.current.rotation.x += 0.01))

  return (
    <mesh
      ref={ref}
      scale={clicked ? 3 : 3}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}>
      <boxGeometry args={[1, 1, 1]} />
      <pointsMaterial color={hovered ? 'grey' : '#286632'} />
    </mesh>
  )
}
export default Box

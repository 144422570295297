import React, { useEffect, useState } from "react";
import Api from '../../services/Api';
import Header from '../../components/Header/Header'
import { Form, Container, Row, Col, Card, Button, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";

const Muf = (props) => {
  const [serial, setSerial] = useState("NMUF-00000");
  const [commandLimp, setCommandLimp] = useState('');
  const [commandIdle, setCommandIdle] = useState('');
  const [commandFrqvz, setCommandFrqvz] = useState('');
  const [commandRange, setCommandRange] = useState('');
  const [commandDosan, setCommandDosan] = useState('');
  const [commandStab, setCommandStab] = useState('');
  const [commandMoist, setCommandMoist] = useState('');
  const [commandReset, setCommandReset] = useState('');
  const [commandNserie, setCommandNserie] = useState('');

  const handleSendData = async (parametro1, parametro2) => {
    const command = `${parametro1}:${parametro2}`;
    console.log(command)
    try {
      var data = await Api.post(`/commands`, { "serialnumber": serial, "command": command })
      if (data.status === 200) {
        console.log("Comando enviado!")
      }
    } catch (error) {
      alert(error.message)
    }
  }

  const handleRosc = async (item) => {
    try {
      var data = await Api.post(`/commands`, { "serialnumber": serial, "command": item })
      if (data.status === 200) {
        console.log("Comando enviado!")
      }
    } catch (error) {
      alert(error.message)
    }
  }

  return (
    <>
      <Header />
      <Container className="pt-2 mb-2">
        <Card className="text-center p-3" style={{ background: "silver" }}>
          <Col style={{ marginLeft: "auto", marginRight: "auto", }} xs={12} sm={8} md={6} lg={5} xl={5}>
            <Form.Label>EQUIPAMENTO</Form.Label>
            <Form.Control
              className="mb-3"
              type="text"
              value={serial}
              onChange={event => { setSerial(event.target.value) }}
              placeholder="SERIAL"
            />
            <hr />

            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleRosc("ROSCON") }}
            >ROSCON</Button>
            <hr />

            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleRosc("ROSCOF") }}
            >ROSCOF</Button>
            <hr />

            {/* ------------------------------------------ */}

            <Form.Control
              className="mb-3"
              type="text"
              value={commandLimp}
              onChange={event => { setCommandLimp(event.target.value) }}
              placeholder="Tempo auto limpeza"
            />
            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleSendData("LIMPF", commandLimp) }}
            >ENVIAR</Button>
            <hr />

            <Form.Control
              className="mb-3"
              type="text"
              value={commandIdle}
              onChange={event => { setCommandIdle(event.target.value) }}
              placeholder="Heart beat"
            />
            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleSendData("IDLE", commandIdle) }}
            >ENVIAR</Button>
            <hr />

            <Form.Control
              className="mb-3"
              type="text"
              value={commandFrqvz}
              onChange={event => { setCommandFrqvz(event.target.value) }}

              placeholder="Frequência vazio"
            />
            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleSendData("FRQVZ", commandFrqvz) }}
            >ENVIAR</Button>
            <hr />

            <Form.Control
              className="mb-3"
              type="text"
              value={commandRange}
              onChange={event => { setCommandRange(event.target.value) }}
              placeholder="Range auto-início"
            />
            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleSendData("RANGE", commandRange) }}
            >ENVIAR</Button>
            <hr />

            <Form.Control
              className="mb-3"
              type="text"
              value={commandDosan}
              onChange={event => { setCommandDosan(event.target.value) }}
              placeholder="Tempo dosando"
            />
            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleSendData("DOSAN", commandDosan) }}
            >ENVIAR</Button>
            <hr />

            <Form.Control
              className="mb-3"
              type="text"
              value={commandStab}
              onChange={event => { setCommandStab(event.target.value) }}
              placeholder="Tempo estabilizando"
            />
            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleSendData("STAB", commandStab) }}
            >ENVIAR</Button>
            <hr />

            <Form.Control
              className="mb-3"
              type="text"
              value={commandMoist}
              onChange={event => { setCommandMoist(event.target.value) }}
              placeholder="Umidade"
            />
            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleSendData("MOIST", commandMoist) }}
            >ENVIAR</Button>
            <hr />

            <Form.Control
              className="mb-3"
              type="text"
              value={commandReset}
              onChange={event => { setCommandReset(event.target.value) }}
              placeholder="Reset ALL"
            />
            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleSendData("RESET", commandReset) }}
            >ENVIAR</Button>
            <hr />

            <Form.Control
              className="mb-3"
              type="text"
              value={commandNserie}
              onChange={event => { setCommandNserie(event.target.value) }}
              placeholder="Número de série"
            />
            <Button
              className="w-100"
              variant="success"
              disabled={false}
              onClick={() => { handleSendData("NSERIE", commandNserie) }}
            >ENVIAR</Button>
          </Col>
        </Card>
      </Container>
    </>
  )
}
export default Muf;
const Command = [
  { "id": 1, "command": "DC_Cami_Dosador", "name": "Dosador" },
  { "id": 2, "command": "DC_Cami_Funil", "name": "Funil" },
  { "id": 3, "command": "Pos_Copo", "name": "Posição do Copo" },
  { "id": 4, "command": "Pos_Vass", "name": "Posição da Vassoura" },
  { "id": 5, "command": "RunMode", "name": "Run Mode" },
  { "id": 6, "command": "Debug", "name": "Debug" },
  { "id": 7, "command": "Debug_Mode", "name": "Debug Mode" },
  { "id": 8, "command": "Salve_ns", "name": "Salve NS" },
  { "id": 9, "command": "Calib_0", "name": "Calibrar 0" },
  { "id": 10, "command": "Calib_200", "name": "Calibrar 200" },
  { "id": 11, "command": "errorS", "name": "Error" },
  { "id": 12, "command": "Calib_Vol", "name": "Calibrar Volume" },
  { "id": 13, "command": "densEsf", "name": "Densidade Esfera" },
  { "id": 14, "command": "DC_Rosca", "name": "Rosca" },
  { "id": 15, "command": "Mont_Dbg", "name": "Monitoramento Debug" },
  { "id": 16, "command": "Reset", "name": "Reiniciar Equipamento" },
  { "id": 17, "command": "Interval", "name": "Intervalo" },
  { "id": 18, "command": "ajCopo", "name": "Ajuste do Copo" },
  { "id": 19, "command": "Memory", "name": "Dados da Memória" },
]
export default Command;